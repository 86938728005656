.main-container {
    display: flex;
    justify-content: center;
    height: 100%;
}

.home-container {
    background-color: rgb(211 243 243);
    min-height: 600px;
    width: 60%;
    text-align: center;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;
    border: 5px solid#171414;
    border-radius: 20px;
}

.create-task {
    display: flex;
    justify-content: start;
}

.input-task {
    margin: 16px 40px;
}

.button-task {
    margin: 16px 40px 16px -30px;
}

.home-title {
    letter-spacing: 5px;
    word-spacing: 5px;
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: 700;
}

.home-userlist {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin: 0px;
}

.home-user {
    text-align: left;
    width: 80%;
    background-color: rgb(252, 156, 149);
    padding: 0.5rem;
    border-radius: 12px;
    margin: 5px 0px;
    font-size: 18px;
    font-weight: 550;
}

.isActProgress {
    background: rgb(110, 240, 129);
}

.home-role {
    margin-top: 1rem;
    font-size: 1.25rem;
}

.user-container {
    display: flex;
    width: 90%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.delete-user {
    cursor: pointer;
    font-size: 1rem;
    background-color: rgb(240, 34, 96);
    padding: 0.35rem;
    border-radius: 12px;
    color: white;
}

.delete-user:hover {
    background-color: rgb(207, 18, 75);
}

.navbar {
    display: flex;
}

.errorMsg {
    margin-top: 3rem;
    font-size: 1.5rem;
}


.container-checkbox {
    width: 10%;
    display: block;
    position: relative;
    cursor: pointer;
    margin-top: -24px;
    margin-left: 24px;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ccc;
    border: 1px solid#000;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input~.checkmark {
    background-color: #797676;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.not-user {
    display: flex;
    justify-content: center;
}
.not-user-body{
    margin: 48px;
    width: 500px;
    height: 200px;
    border: 5px solid#ccc;
    border-radius: 25px;
    background-color: rgb(211 243 243);
}
.not-user-body h1{
    font-size:28px;
    letter-spacing: 3px;
    word-spacing: 8px;
    font-weight: 700;
    color: rgb(25, 120, 180);
    margin: 24px;
}
.not-user-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.not-user-content h1{
    font-size: 56px;
    padding: 0;
    margin-top: 16px;
}
.not-user-link{
    font-size: 56px;
    padding: 10px;
    text-decoration:none;
    color: rgb(25, 120, 180);
    border: 1px solid#ccc;
    border-radius: 25px;
    background: rgb(207, 229, 243);
    margin-left: -10px;
}
.not-user-link:hover{
    font-weight: 700;
    color: rgb(20, 102, 154);
    background: rgb(194, 222, 240);


}