.register-section{
  display: flex;
  justify-content: center;
}
.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  border-radius: 12px;
  max-height: 600px;
  min-height: 400px;
  width: 400px;
  min-width: 20%;
  background-color: rgb(218, 218, 218);
  text-align:center;
}
.form-register{
  width: 300px;
}
.register-title {
  font-weight: 600;
  font-size: 1.5rem;
}
.register-error{
  margin-bottom: 2px;
  align-self:center;
  color:rgb(255, 0, 55);
}
button{
  background-color: #6e6969;
}
button:hover{
  background-color: #131212;
}