.login-section
{
  display: flex;
  justify-content: center;
}
.login-container {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-top: 40px;
  border-radius: 12px;
  background-color: rgb(218, 218, 218);
  width: 400px;
  height: 400px;
}
.login-title {
  margin-top: 40px;
  font-weight: 700;
  font-size: 1.5rem;
}
form {
  display: flex;
  margin-top: 1rem;
  font-size: 0.85rem;
  font-weight: 600;
  width: 300px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
input {
  border: none;
  padding: 0.35rem;
  font-family: "Inter", sans-serif;
  margin: 0.75rem 0;
  border-radius: 4px;
  width: 100%;
}
button {
  cursor: pointer;
  border: none;
  align-self: center;
  margin-top: 1rem;
  font-size: 1rem;
  padding: 0.65rem;
  border-radius: 4px;
  color: white;
  background-color: rgb(161, 161, 161);
}
.login-register {
  margin: 2rem 1rem 0 1rem;
  font-size: 1rem;
}
.login-register-link {
  text-decoration: none;
  cursor: pointer;
  margin-top: 1rem;
  color: rgb(105, 105, 105);
  font-weight: 700;
}
.login-register-link:hover{
  color: rgb(57, 56, 56);
}
